import React, { FunctionComponent } from 'react';
import { Text, ColorNameEnum, SizeEnum, Button } from '@getvim/atomic-ui';

const SelectedProviderActions: FunctionComponent<{
  onClose: () => void;
  displayCopyButton: boolean;
}> = ({ onClose, displayCopyButton }) => {
  return (
    <>
      <div className="banner-text">
        <div>
          <Text size={SizeEnum['14px']} colorName={ColorNameEnum.ultraDark}>
            {displayCopyButton
              ? 'Provider’s details were copied to the clipboard'
              : 'Provider’s details were updated in your EHR'}
          </Text>
        </div>
      </div>
      <div className="banner-buttons">
        <Button buttonType="small" bgColor="themedOutline" width="small" onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );
};

export default SelectedProviderActions;
