export const FIND_QUERY = `
  query find($input: FindInput!) {
    find(input: $input) {
      results {
        providerType
        npi
        firstName
        lastName
        name
        specialties {
          id
          description
          isPrimary
        }
        preferredProvider
        locations {
          providerLocationId
          addressLine1
          addressLine2
          city
          state
          zip
          distance
          phoneNumber {
            number
          }
          performance {
            code
            description
          }
          costEstimation {
            description
            min
            max
          }
        }
        implementationSpecificFields
      }
      alternativeResults {
        providerType
        npi
        firstName
        lastName
        name
        specialties {
          id
          description
        }
        locations {
          addressLine1
          addressLine2
          city
          state
          zip
          distance
          phoneNumber {
            number
          }
          performance {
            code
            description
          }
          costEstimation {
            description
            min
            max
          }
        }
        implementationSpecificFields
      }
      pagination {
        hasNextPage
        pageNumber
        totalPages
      }
      implementationSpecificFields
    }
  }
`;

export default FIND_QUERY;
