import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  BaseLayout,
  PoweredByVimFooter,
  GrayBgTemplate,
  Button,
  Loader,
  AsoMetadata,
} from '@getvim/atomic-ui';
import { useTheme } from '@getvim/components-hooks-use-theme';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import {
  AnalyticsInstance,
  EventCreatorType,
  SearchActionEvents,
} from '@getvim/components-utils-analytics';
import { v4 as uuid } from 'uuid';
import isEqual from 'lodash.isequal';

import SearchForm from '../../components/SearchForm/SearchForm';
import * as Api from '../../api/api';
import authHandler from '../../api/authHandler';
import {
  defaultFilterValues,
  defaultFormValues,
  BarFiltersType,
  FormDefTypes,
  validateParams,
} from './formDef';
import SearchResults from '../../components/searchResults/SearchResults';
import { compact } from '../../utils/general';
import {
  FindRequest,
  FreeTextOption,
  SearchParams as SearchParamsType,
} from '../../api/requestTypes';
import SearchEvents from '../../utils/events/searchEvents';
import { TaxonomiesResponse } from '../../api/responseTypes';

import FiltersBar from '../../components/Filters/FiltersBar';
import './SearchAndAction.less';
import { GetPortalResult, PortalOption, Location } from '../../models/FindResponse';

const RESULTS_LIMIT = 5;
const NO_RESULTS = { providers: [], pagination: { totalPages: 0, pageNumber: 0 } };

const extractExternalFields = (
  {
    npi,
    firstName,
    middleName,
    lastName,
    suffix,
    profilePicture,
    gender,
    acceptingNewPatients,
    languages,
    taxonomies,
  }: PortalOption,
  { address, fax, phoneNumber }: Location,
) => ({
  npi,
  address,
  firstName,
  middleName,
  lastName,
  suffix,
  profilePicture,
  languages,
  gender,
  acceptingNewPatients,
  taxonomies,
  fax,
  phoneNumber,
});

const mergeSearchParams = (searchParams: SearchParamsType): FormDefTypes => {
  return {
    ...defaultFormValues,
    ...compact({ ...searchParams }),
    filters: {
      ...defaultFormValues.filters,
      ...compact(searchParams.filters),
    },
  };
};

const convertSpecialties = (response: TaxonomiesResponse) => {
  return response.data
    .map((tax) => ({ value: tax.codes, label: tax.name }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

function checkIfPartialEqual<T>(original: T, partial: Partial<T>): boolean {
  return Object.keys(partial).every((key) => {
    const currKey = key as keyof T;
    return isEqual(partial[currKey], original[currKey]);
  });
}

const SearchAndAction: FunctionComponent<{
  analytics: AnalyticsInstance;
  isGoogleApiLoaded: boolean;
  initialSearchParams: SearchParamsType;
  clearInitialSearchParams: () => void;
}> = ({ analytics, isGoogleApiLoaded, initialSearchParams, clearInitialSearchParams }) => {
  const theme = useTheme();

  const [allTaxonomies, setAllTaxonomies] = useState<FreeTextOption[] | null>();
  const [searchResults, setSearchResults] = useState<GetPortalResult | null | undefined>();
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPageNumberChanged, setIsPageNumberChanged] = useState(false);
  const [asoMetadata, setAsoMetadata] = useState<AsoMetadata>();
  const [scrollable, setScrollable] = useState(true);
  const [selectedProviderLocation, setSelectedProvider] = useState<{
    provider: PortalOption;
    location: Location;
  }>();
  const [enableReset, setEnableReset] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [isFormValidated, setIsFormValidated] = useState<boolean>(false);

  const sdkEvents = useSdkEvents();

  const insurer = initialSearchParams?.insurer;
  const displayCopyButton = !!initialSearchParams?.displayCopyButton;
  const displaySearchButton = !!initialSearchParams?.displaySearchButton;
  const portal = !!initialSearchParams?.portal;

  const isSearchButtonDisplayed = displaySearchButton || portal;

  useEffect(() => {
    if (insurer || insurer === '') {
      Api.getAllTaxonomies(insurer)
        .then((res) => setAllTaxonomies(convertSpecialties(res)))
        .catch((error) => {
          authHandler(error);
          setAllTaxonomies(null);
        });
    }
  }, [insurer]);

  const formik = useFormik<FormDefTypes>({
    initialValues: initialSearchParams ? mergeSearchParams(initialSearchParams) : defaultFormValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (initialSearchParams?.memberToken) {
      Api.asoMetadata(initialSearchParams.memberToken, insurer)
        .then(setAsoMetadata)
        .catch(authHandler);
    }
  }, [initialSearchParams?.memberToken, insurer]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearState = () => {
    clearInitialSearchParams();
    setAsoMetadata(undefined);
    setSearchResults(undefined);
    setSelectedProvider(undefined);
    setIsFormTouched(false);
    setPageNumber(1);
    setIsPageNumberChanged(false);
  };

  const handleValuesUpdate = (
    update: Partial<FormDefTypes>,
    analyticsEventCreator?: EventCreatorType,
  ) => {
    setIsFormTouched(true);
    const isChanged = !checkIfPartialEqual(formik.values, update);
    if (!isChanged) return;

    const updatedValues = { ...formik.values, ...update };

    if (validateParams(updatedValues)) updatedValues.queryId = uuid();

    if (analyticsEventCreator) {
      const events = analyticsEventCreator(updatedValues.queryId, updatedValues.memberSessionId);
      for (const currEvent of events) {
        const { eventName, params } = currEvent;
        analytics.track(eventName, params);
      }
    }

    formik.setValues(updatedValues);

    if (!isSearchButtonDisplayed) {
      setIsPageNumberChanged(false);
      setPageNumber(1);
    }
  };

  const onReset = () => {
    const updatedValues = {
      filters: { ...formik.values.filters, ...defaultFilterValues },
    };

    setEnableReset(false);
    handleValuesUpdate(updatedValues);
  };

  useEffect(() => {
    if (!isSearchButtonDisplayed && isNonPortalSearchValid()) {
      onSearch();
    }
  }, [formik.values, isFormTouched]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isSearchButtonDisplayed && !isNonPortalSearchValid()) return;

    if (isSearchButtonDisplayed && !isPageNumberChanged) return;

    onSearch();
  }, [pageNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const isNonPortalSearchValid = (): boolean => {
    const isParamsValid = validateParams(formik.values);
    if (!isParamsValid) {
      if (isFormTouched) {
        setSearchResults(NO_RESULTS);
      } else {
        setSearchResults(undefined);
      }
    }
    return isParamsValid;
  };

  const onSearch = () => {
    setSearchLoading(true);
    Api.findAnthem({
      ...formik.values,
      geo: formik.values.geo!,
      limit: RESULTS_LIMIT,
      skip: (pageNumber - 1) * RESULTS_LIMIT,
      pageNumber,
    } as FindRequest)
      .then((result) => {
        if (result && result.providers.length === 0) {
          const { eventName, params } = SearchEvents.noResults({
            queryId: formik.values.queryId,
            memberSessionId: formik.values.memberSessionId,
          });
          analytics.track(eventName, params);
        }

        setSearchResults(result);
      })
      .catch(authHandler)
      .then(() => {
        setSearchLoading(false);
      });
  };

  const handleSearchClick = () => {
    const isParamsValid = validateParams(formik.values);

    if (!isParamsValid) {
      setIsFormValidated(true);
    } else {
      const { queryId, memberSessionId, filters } = formik.values;

      if (filters.taxonomy && filters.taxonomy.length && filters.distance) {
        const { eventName, params } = SearchActionEvents.searchButtonClick({
          filters: {
            distance: filters.distance!,
            taxonomy: filters.taxonomy!,
          },
          geo: formik.values.geo!,
          queryId,
          memberSessionId,
        });

        analytics.track(eventName, params);
      }

      onSearch();
      setPageNumber(1);
    }
  };

  return (
    <div>
      <div className="portal-referral-guidance-widget">
        <BaseLayout scrollable={scrollable}>
          {!portal ? (
            <Button
              onClick={() => {
                sdkEvents.closed();
                clearState();
              }}
              className="close-dialog-btn"
              aria-label="close"
              buttonType="link"
              color="darkGray"
            >
              <i className="icon-x text-18 i-va-fix-3" />
            </Button>
          ) : (
            <></>
          )}
          <GrayBgTemplate
            topSectionClass="no-padding"
            bottomSectionClass="white"
            topSectionContent={
              <SearchForm
                insurer={insurer}
                values={formik.values}
                onChange={handleValuesUpdate}
                allTaxonomies={allTaxonomies || undefined}
                analytics={analytics}
                isGoogleApiLoaded={isGoogleApiLoaded}
                isSearchButtonDisplayed={isSearchButtonDisplayed}
                handleSearchClick={handleSearchClick}
                isFormValidated={isFormValidated}
              />
            }
            bottomSectionContent={
              <>
                <div className="filters-bar">
                  <FiltersBar
                    lightBg
                    theme={theme}
                    enableReset={enableReset}
                    filters={formik.values.filters}
                    onChange={(updates: Partial<BarFiltersType>, analyticsEventCreator) => {
                      setEnableReset(!checkIfPartialEqual(defaultFilterValues, updates));

                      const updatedFilters = {
                        ...formik.values.filters,
                        ...updates,
                      };
                      handleValuesUpdate(
                        {
                          filters: updatedFilters,
                        },
                        analyticsEventCreator,
                      );
                    }}
                    onReset={onReset}
                  />
                </div>
                {isSearchLoading ? (
                  <Loader className="margin-top-50" type="calendar" />
                ) : (
                  <SearchResults
                    insurer={insurer}
                    searchResults={searchResults}
                    memberToken={initialSearchParams?.memberToken}
                    memberPhoneNumber={initialSearchParams?.options?.memberPhoneNumber}
                    asoMetadata={asoMetadata}
                    memberSessionId={formik.values.memberSessionId}
                    queryId={formik.values.queryId!}
                    analytics={analytics}
                    onSelect={(selectedNpi) => {
                      if (!portal) setScrollable(!selectedNpi);
                    }}
                    onConfirm={(provider, location) =>
                      sdkEvents.selected(extractExternalFields(provider, location))
                    }
                    resultsLimit={RESULTS_LIMIT}
                    onFinish={() => {
                      sdkEvents.finished();
                      clearState();
                    }}
                    pageNumber={pageNumber}
                    onChangePage={(newPageNumber) => {
                      const isParamsValid = validateParams(formik.values);
                      if (!isParamsValid) {
                        setIsFormValidated(true);
                      } else {
                        if (!isPageNumberChanged) setIsPageNumberChanged(true);

                        setPageNumber(newPageNumber);
                      }
                    }}
                    selectedProviderLocation={selectedProviderLocation}
                    setSelectedProvider={setSelectedProvider}
                    displayCopyButton={displayCopyButton}
                    portal={portal}
                  />
                )}
              </>
            }
          />
        </BaseLayout>
        <PoweredByVimFooter hovering />
      </div>
    </div>
  );
};

export default SearchAndAction;
