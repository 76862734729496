import React, { FunctionComponent } from 'react';
import SelectedProviderActions from './Components/SelectedProviderActions';
import { Location } from '../../models/FindResponse';

import './ResultCardBanner.less';

export interface ResultCardBannerProps {
  npi: string;
  fullProviderName?: string;
  location: Location;
  insurer?: string;
  memberToken?: string;
  onCancel: () => void;
  onClose: () => void;
  onShareSuccess?: (correlationId: string) => void;
  onShareClick?: () => void;
  onShareSubmitClick?: () => void;
  onShareFailed?: (error: any) => void;
  memberPhoneNumber?: string;
  displayCopyButton: boolean;
}

const ResultCardBanner: FunctionComponent<ResultCardBannerProps> = ({
  npi,
  fullProviderName,
  location,
  insurer,
  onCancel,
  onClose,
  onShareSuccess = () => {},
  onShareClick = () => {},
  onShareSubmitClick = () => {},
  onShareFailed = () => {},
  memberToken,
  memberPhoneNumber,
  displayCopyButton,
}) => {
  return (
    <div className="result-card-banner">
      <SelectedProviderActions displayCopyButton={displayCopyButton} onClose={onClose} />
    </div>
  );
};

export default ResultCardBanner;
