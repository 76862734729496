function snippetChecks(apiKey: string, systemName: string) {
  if (!apiKey) {
    return Promise.reject(new Error(`Can not init ${systemName} without an api key!`));
  }
  if (typeof document === 'undefined') {
    return Promise.reject(
      new Error(`document was not found, can only init ${systemName} on the client side!`),
    );
  }

  return Promise.resolve();
}

const loadDynamicScript = (id: string, src: string) => {
  return new Promise<void>((resolve) => {
    const existingScript = document.getElementById(id);

    if (!existingScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.id = id;
      document.body.appendChild(script);

      script.onload = () => {
        resolve();
      };
    }

    if (existingScript) resolve();
  });
};

const loadGooglePlacesAutoCompleteApi = (apiKey: string) => {
  if (!window?.google?.maps) {
    return snippetChecks(apiKey, 'Google places auto complete api')
      .then(() =>
        loadDynamicScript(
          'googleMaps',
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        ),
      )
      .then(() => {
        const {
          google: { maps },
        } = window;
        if (typeof maps === 'undefined') {
          throw new Error('Google api for places auto complete failed to initialize');
        }
        return true;
      });
  }
  return Promise.resolve(true);
};

export default loadGooglePlacesAutoCompleteApi;
