let accessToken: string;
export function setAccessToken(token: string): void {
  accessToken = token;
}
export function getAccessToken(): string {
  return accessToken;
}

let apiKey: string;
export function setApiKey(key: string) {
  apiKey = key;
}
export function getApiKey(): string {
  return apiKey;
}

let memberToken: string;
export function setMemberToken(token: string): void {
  memberToken = token;
}
export function getMemberToken(): string {
  return memberToken;
}
