import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeContext, ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import { TranslateProvider } from '@getvim/translate';
import 'bootstrap/less/bootstrap.less';
import '@getvim/atomic-ui/assets/styles/main.less';
import { initAnalyticsReporter } from '@getvim/components-utils-analytics';
import SearchAndActionWrapper from './pages/SearchAndAction/SearchAndActionWrapper';
import SessionExpiredErrorPage from './pages/SessionExpiredErrorPage';
import config from './config';
import loadGooglePlacesAutoCompleteApi from './utils/googleApi/loadGooglePlacesAutoCompleteApi';
import * as logger from './utils/logger';
import FrontToastContainer from './components/ToastNotifications/ToastNotifications';
import PortalReferralGuidanceFeatureFlagProvider from './core/featureFlag';

const { VIM_SEGMENT_API_KEY: writeKey, VIM_GOOGLE_API_KEY: apiKey } = config;

function App() {
  const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);
  const [cssVariablesPolyfillLoaded, setCssVariablesPolyfillLoaded] = useState(false);

  useEffect(() => {
    loadGooglePlacesAutoCompleteApi(apiKey)
      .then(setIsGoogleApiLoaded)
      .catch((error) => logger.error(error));
  }, []);

  useEffect(() => {
    const loadPolyfill = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // check if we're on IE11
        if (window.MSInputMethodContext && document.documentMode) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await import('ie11-custom-properties');
          logger.log(`Loaded IE11 CSS variables polyfill`);
        }
      } catch (e) {
        logger.error(`Failed to load IE11 CSS variables polyfill`, e);
      }
      setCssVariablesPolyfillLoaded(true);
    };
    loadPolyfill();
  }, []);

  const analytics = initAnalyticsReporter({
    segmentApiKey: writeKey,
    appName: 'PORTAL-REFERRAL-GUIDANCE',
  });
  const theme = themes.vim;
  ThemeContext(theme);
  if (!cssVariablesPolyfillLoaded) {
    return null;
  }
  return (
    <ThemeVariablesWrapper theme={theme}>
      <PortalReferralGuidanceFeatureFlagProvider>
        <TranslateProvider locale="en" rootTranslations={{ en: {}, es: {} }}>
          <Switch>
            <Route path="/sessionexpired">
              <SessionExpiredErrorPage />
            </Route>
            <Route path="/">
              <ToastProvider
                autoDismiss
                placement="top-right"
                components={{ ToastContainer: FrontToastContainer }}
              >
                <SearchAndActionWrapper
                  analytics={analytics}
                  isGoogleApiLoaded={isGoogleApiLoaded}
                />
              </ToastProvider>
            </Route>
          </Switch>
        </TranslateProvider>
      </PortalReferralGuidanceFeatureFlagProvider>
    </ThemeVariablesWrapper>
  );
}

export default App;
