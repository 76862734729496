import React, { useState } from 'react';
import { components, OptionProps } from 'react-select';
import debounce from 'debounce';
import Highlight from 'react-highlighter';
import { deprecated } from '@getvim/atomic-ui';
import classnames from 'classnames';
import { NOT_FOUND } from 'http-status-codes';
import {
  typeaheadEvent,
  EventCreatorType,
  TypeaheadEventType,
} from '@getvim/components-utils-analytics';
import { FreeTextResponse, GetByNpiResponse } from '../../api/responseTypes';
import SearchType from '../../models/SearchType';
import { FreeTextRequest, GetByNpiRequest } from '../../api/requestTypes';
import Formatter from '../../utils/formatter';
import authHandler from '../../api/authHandler';
import ApiError, { isApiError } from '../../api/ApiError';

import './ProviderTypeahead.less';

interface ProviderOptionExtension extends deprecated.DefaultOptionType {
  data?: any;
  info?: string;
  className?: string;
  taxonomies?: string[];
  freeText: string;
  value: any;
  label: any;
}

const ProviderOptionItem = (props: OptionProps<ProviderOptionExtension>) => {
  const {
    label,
    data: { className, data, info, freeText },
  } = props;
  return (
    <components.Option {...props}>
      <div className={className}>
        <deprecated.ProviderLogo provider={data} containerClass="select-option-rounded-img" />

        <span className={classnames('option-name', { 'has-input-text': freeText })}>
          <Highlight search={freeText}>{label}</Highlight>
        </span>
        <div className="option-info">{info}</div>
      </div>
    </components.Option>
  );
};

let lastTerm = '';

type ThemeType = {
  mainColor: string;
  secondaryColor: string;
};

const defaultMessage = 'Search by name or NPI';

function ProviderTypeahead({
  value,
  onChange,
  fetchFreeText,
  fetchGetByNpi,
  onTypeaheadEntered = () => {},
  theme,
}: {
  value?: string | null;
  onChange: (option: ProviderOptionExtension) => void;
  fetchFreeText: (args: FreeTextRequest) => Promise<FreeTextResponse>;
  fetchGetByNpi: (args: GetByNpiRequest) => Promise<GetByNpiResponse>;
  onTypeaheadEntered?: (
    event: TypeaheadEventType<{ npi: string; firstName: string; lastName: string }>,
    eventCreator: EventCreatorType,
  ) => void;
  theme: ThemeType;
}) {
  const [options, setOptions] = useState<ProviderOptionExtension[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noOptionMessage, setNoOptionMessage] = useState<string>(defaultMessage);

  const onProviderChanged = (freeText: string) => {
    const type = SearchType.PROVIDER;
    if (!freeText) {
      setNoOptionMessage(defaultMessage);
    } else if (isNaN(parseInt(freeText, 10))) {
      lastTerm = freeText;
      setNoOptionMessage(defaultMessage);
      setIsLoading(true);
      fetchFreeText({
        freeText,
        types: [type],
      })
        .then(({ data }) => {
          if (freeText === lastTerm) {
            setIsLoading(false);
            setOptions(
              data.suggestions.provider.map((provider) => ({
                className: 'profile-img-option',
                data: provider,
                freeText,
                info: provider.taxonomies
                  .map(({ subSpecialtyDescription }) => subSpecialtyDescription)
                  .join(', '),
                taxonomies: provider.taxonomies.map((tax) => tax.taxonomyCode),
                value: provider.npi,
                label: Formatter.formatProviderTitle(provider),
              })),
            );
            onTypeaheadEntered(
              { term: freeText, type, results: data.suggestions.provider },
              (queryId, memberSessionId) => [
                typeaheadEvent.contentEntered<{ npi: string; firstName: string; lastName: string }>(
                  {
                    queryId,
                    memberSessionId,
                    results: data.suggestions.provider,
                    type,
                    term: freeText,
                    formatter: (results) => ({
                      physicians: results.map(({ firstName, lastName, npi }) => ({
                        firstName,
                        lastName,
                        npi,
                      })),
                    }),
                  },
                ),
              ],
            );
          }
        })
        .catch(authHandler)
        .then(() => setIsLoading(false));
    } else if (freeText.length !== 10) {
      const message = 'Please enter an NPI with 10 digits';
      setNoOptionMessage(message);
      onTypeaheadEntered(
        { term: freeText, type, results: [], message },
        (queryId, memberSessionId) => [
          typeaheadEvent.contentEntered<{ npi: string; firstName: string; lastName: string }>({
            queryId,
            memberSessionId,
            message,
            results: [],
            type,
            term: freeText,
            formatter: (results) => ({
              physicians: results.map(({ firstName, lastName, npi }) => ({
                firstName,
                lastName,
                npi,
              })),
            }),
          }),
        ],
      );
    } else {
      setIsLoading(true);
      setNoOptionMessage(defaultMessage);
      lastTerm = freeText;
      fetchGetByNpi({ npi: freeText })
        .then((provider) => {
          if (lastTerm === freeText) {
            setIsLoading(false);
            setOptions([
              {
                className: 'profile-img-option',
                data: provider,
                freeText,
                info: provider.taxonomies
                  .map(({ subSpecialtyDescription }) => subSpecialtyDescription)
                  .join(', '),
                taxonomies: provider.taxonomies.map((tax) => tax.taxonomyCode),
                value: provider.npi,
                label: Formatter.formatProviderTitle(provider),
              },
            ]);
            onTypeaheadEntered(
              { term: freeText, type, results: [provider] },
              (queryId, memberSessionId) => [
                typeaheadEvent.contentEntered<{ npi: string; firstName: string; lastName: string }>(
                  {
                    queryId,
                    memberSessionId,
                    results: [provider],
                    type,
                    term: freeText,
                    formatter: (results) => ({
                      physicians: results.map(({ firstName, lastName, npi }) => ({
                        firstName,
                        lastName,
                        npi,
                      })),
                    }),
                  },
                ),
              ],
            );
          }
        })
        .catch((error) => {
          if (isApiError(error) && (error as ApiError).status === NOT_FOUND) {
            setIsLoading(false);
            const message = "Provider wasn't found";
            setNoOptionMessage(message);
            setOptions([]);
            onTypeaheadEntered(
              { term: freeText, message, type, results: [] },
              (queryId, memberSessionId) => [
                typeaheadEvent.contentEntered<{ npi: string; firstName: string; lastName: string }>(
                  {
                    queryId,
                    memberSessionId,
                    results: [],
                    type,
                    term: freeText,
                    message,
                    formatter: (results) => ({
                      physicians: results.map(({ firstName, lastName, npi }) => ({
                        firstName,
                        lastName,
                        npi,
                      })),
                    }),
                  },
                ),
              ],
            );
          } else {
            authHandler(error);
          }
        });
    }
  };
  return (
    <deprecated.SelectInput<ProviderOptionExtension>
      placeholder="Name/NPI"
      value={value}
      options={options}
      onChange={(changes) => onChange(changes?.[0])}
      theme={theme}
      onInputChange={debounce((text: string) => onProviderChanged(text))}
      noOptionsMessage={() => noOptionMessage}
      isLoading={isLoading}
      components={{
        Option: ProviderOptionItem,
      }}
    />
  );
}

export default ProviderTypeahead;
