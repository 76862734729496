import { GeoType, GeoTypeV } from '../../models/Geo';
import { isTypeOf } from '../../models/utils';

export declare type BarFiltersType = {
  distance: number;
};

export type AnalyticableFilters = BarFiltersType & { geo: GeoType | null };

export type FormFiltersValues = BarFiltersType & {
  taxonomy: string[] | null;
  npiList: string[] | null;
};

// TODO: implement ability to parse searchTerm with interface:
// TODO: {category: 'SPECIALTY' | 'PROVIDER', term: string} and fill from with this data
export type FormDefTypes = {
  memberToken?: string;
  queryId?: string;
  memberSessionId?: string | null;
  plan?: string;
  geo?: GeoType | null;
  filters: FormFiltersValues;
};

export const defaultFilterValues: BarFiltersType = {
  distance: 10,
};

export const defaultFormValues: FormDefTypes = {
  filters: {
    ...defaultFilterValues,
    taxonomy: null,
    npiList: null,
  },
};

export const validateParams = (params: { filters?: any; geo?: GeoType | null } = {}) => {
  const { filters, geo } = params;
  const { taxonomy, npiList } = filters || {};
  // Params here sometimes come from parent iframe or from query params, so we check them strictly
  return !!((taxonomy || npiList) && isTypeOf(geo, GeoTypeV));
};
