import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import isNil from 'lodash.isnil';
import { InputStyleWrapper, InputStyle, LocationSelect, Button } from '@getvim/atomic-ui';
import { useTheme } from '@getvim/components-hooks-use-theme';
import {
  withAnalyticsProp,
  typeaheadEvent,
  EventCreatorType,
} from '@getvim/components-utils-analytics';
import * as Api from '../../api/api';
import { FormDefTypes } from '../../pages/SearchAndAction/formDef';
import SearchType from '../../models/SearchType';
import FreeTextTypeahead from './FreeTextTypeahead';
import ProviderTypeahead from './ProviderTypeahead';
import { GeoTypeV } from '../../models/Geo';
import { assertType } from '../../models/utils';
import SearchEvents from '../../utils/events/searchEvents';
import './SearchForm.less';
import { FreeTextOption } from '../../api/requestTypes';

type SearchFormPropsType = {
  values: FormDefTypes;
  onChange: (update: Partial<FormDefTypes>, analyticsEventCreator?: EventCreatorType) => void;
  allTaxonomies?: FreeTextOption[];
  isGoogleApiLoaded: boolean;
  insurer?: string | null;
  isSearchButtonDisplayed: boolean;
  handleSearchClick: () => void;
  isFormValidated: boolean;
} & withAnalyticsProp;

const freeTextApi =
  ({ geo, memberToken, filters }: FormDefTypes, insurer?: string) =>
  ({ freeText, types }: { freeText: string; types: SearchType[] }) =>
    Api.freeText({
      geo: geo!,
      memberToken,
      filters,
      types,
      freeText,
      insurer,
    });

const getByNpiApi =
  ({ memberToken }: FormDefTypes, insurer?: string) =>
  ({ npi }: { npi: string }) =>
    Api.getProviderByNpi({ memberToken, npi, insurer });

const SearchForm: FunctionComponent<SearchFormPropsType> = ({
  values,
  insurer,
  onChange,
  analytics,
  allTaxonomies,
  isGoogleApiLoaded,
  isSearchButtonDisplayed,
  handleSearchClick,
  isFormValidated,
}: SearchFormPropsType) => {
  const theme = useTheme();

  const handleTypeaheadEntered = (data: any, analyticsEventCreator?: EventCreatorType) => {
    if (analyticsEventCreator) {
      const { memberSessionId, queryId } = values;
      const events = analyticsEventCreator(queryId, memberSessionId);
      for (const currEvent of events) {
        const { eventName, params } = currEvent;
        analytics.track(eventName, params);
      }
    }
  };

  // TODO: implement ability to parse searchTerm from 'values' with interface:
  // TODO: {category: 'SPECIALTY' | 'PROVIDER', term: string} and fill from with this data

  return (
    <div className="portal-referral-guidance-widget-form ">
      <div className="row form-inputs">
        <div className="col-md-3">
          <InputStyleWrapper
            className="margin-top-30"
            inputStyle={InputStyle.pill}
            leftIcon={<i className="icon-search-2" />}
          >
            <div className="input">
              <LocationSelect
                onChange={(value) => {
                  assertType(value, GeoTypeV);
                  onChange(
                    {
                      geo: value || undefined,
                    },
                    (queryId, memberSessionId, brokerId) => [
                      SearchEvents.filterClick({
                        filterName: 'geo',
                        memberSessionId,
                        queryId,
                        brokerId,
                        filterValue: value || undefined,
                      }),
                    ],
                  );
                }}
                isGoogleApiLoaded={isGoogleApiLoaded}
                geo={values.geo}
              />
            </div>
            {isFormValidated && isNil(values.geo) ? (
              <div className="required-error">This field is mandatory</div>
            ) : (
              <></>
            )}
          </InputStyleWrapper>
        </div>
        <div className={classNames('col-md-4', { 'col-sm-5': isSearchButtonDisplayed })}>
          <InputStyleWrapper
            className="margin-top-30"
            inputStyle={InputStyle.pill}
            leftIcon={<i className="icon-stethoscope" />}
          >
            <div className="input">
              <FreeTextTypeahead
                insurer={insurer}
                placeholder="Specialty/Subspecialty"
                onChange={(taxonomyOptions) => {
                  const options = taxonomyOptions as FreeTextOption[];
                  onChange(
                    {
                      filters: {
                        ...values.filters,
                        taxonomy: options ? options.flatMap((option) => option.value) : null,
                        npiList: null,
                      },
                    },
                    (queryId, memberSessionId) => [
                      typeaheadEvent.clicked({
                        searchTerm: options
                          ? options.map((option) => option.label).join(', ')
                          : null,
                        selectedKey: 'codes',
                        type: SearchType.NUCC,
                        value: options ? options.flatMap((option) => option.value) : null,
                        queryId,
                        memberSessionId,
                      }),
                    ],
                  );
                }}
                onTypeaheadEntered={handleTypeaheadEntered}
                value={values.filters.taxonomy}
                valueCompare={[
                  (value, optionValue) => JSON.stringify(optionValue) === JSON.stringify(value),
                  (value, optionValue) => value?.some((val) => optionValue.includes(val)),
                ]}
                defaultOptions={allTaxonomies}
                fetchFreeText={Api.freeText}
                type={SearchType.NUCC}
                theme={theme}
              />
            </div>
            <div className="input">
              <ProviderTypeahead
                value={values.filters.npiList ? values.filters.npiList[0] : null}
                onChange={(option) => {
                  onChange(
                    {
                      filters: {
                        ...values.filters,
                        npiList: option ? [option.data.npi] : null,
                        taxonomy: null,
                      },
                    },
                    (queryId, memberSessionId) => [
                      typeaheadEvent.clicked({
                        searchTerm: option ? option.label : null,
                        selectedKey: 'npi',
                        type: SearchType.PROVIDER,
                        value: option?.value,
                        queryId,
                        memberSessionId,
                      }),
                    ],
                  );
                }}
                onTypeaheadEntered={handleTypeaheadEntered}
                fetchFreeText={freeTextApi(values, insurer || undefined)}
                fetchGetByNpi={getByNpiApi(values, insurer || undefined)}
                theme={theme}
              />
            </div>
            {isFormValidated && isNil(values.filters.npiList) && isNil(values.filters.taxonomy) ? (
              <div className="required-error">This field is mandatory</div>
            ) : (
              <></>
            )}
          </InputStyleWrapper>
        </div>
        {isSearchButtonDisplayed && (
          <div className="col-sm-2 search-btn-wrapper">
            <Button
              onClick={handleSearchClick}
              className="search-btn"
              aria-label="search"
              buttonType="link"
              color="white"
            >
              <i className="search-inner-icon icon-search-2" />
              <p className="search-btn__text">Search</p>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchForm;
