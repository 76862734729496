import { pickBy, isEmpty, identity, capitalize, compact } from 'lodash-es';
import parser from 'parse-address';
import { PhoneNumberType } from '../../models/PhoneNumber';
import { TaxonomyType } from '../../models/Taxonomy';
import { PortalOption, Location } from '../../models/FindResponse';
import { GetByNpiResponse } from '../../api/responseTypes';

const suffixesThatShouldntBeUppercase = [
  { original: 'MeD', inUppercase: 'MED' },
  { original: 'PharmD', inUppercase: 'PHARMD' },
  { original: 'EdD', inUppercase: 'EDD' },
  { original: 'AuD', inUppercase: 'AUD' },
  { original: 'PsyD', inUppercase: 'PSYD' },
  { original: 'PhD', inUppercase: 'PHD' },
];

export interface FullAddress {
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  zip?: string | null;
}

function parseSuffix(rawSuffix: string) {
  const rawSuffixInUppercase = rawSuffix.toUpperCase();
  for (const { original, inUppercase } of suffixesThatShouldntBeUppercase) {
    if (rawSuffixInUppercase.match(inUppercase)) return original;
  }
  return rawSuffixInUppercase;
}

function uniqueValuesFilter<T>(currentValue: T, currentIndex: number, sourceArray: T[]) {
  return sourceArray.indexOf(currentValue) === currentIndex;
}

export const capitalizeAsName = (str: string): string => {
  return str
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
};

export function getAddress(
  data: Location,
  shouldCapitalizeAddress = true,
  isFiveDigitZip = true,
): string {
  const { addressLine1, addressLine2, city, state, zip } = data;
  return Formatter.formatAddress(
    { addressLine1, addressLine2, city, state, zip },
    shouldCapitalizeAddress,
    isFiveDigitZip,
  );
}

class Formatter {
  /**
   * Most of the time, physician titles are in uppercase (like MD). Sometimes, they're not (like
   * PhD). This function takes the title/titles from the full name and parses them in the correct
   * capitalization. Also, this function takes into account that some providers have multiple titles
   * separated by comma.
   */
  static formatProviderTitle({
    title,
    firstName,
    middleName,
    lastName,
    suffix,
  }:
    | Pick<PortalOption, 'title' | 'firstName' | 'middleName' | 'lastName' | 'suffix'>
    | Pick<GetByNpiResponse, 'title' | 'firstName' | 'middleName' | 'lastName' | 'suffix'>) {
    // get suffixes in array instead of comma limited string
    const suffixes = suffix?.length
      ? suffix
          .split(',')
          .map((s) => s.trim())
          .filter((s) => s.length)
      : [];
    const parsedSuffix = suffixes.map(parseSuffix).join(', ');
    const fullProviderTitleWithoutSuffix = [title, firstName, middleName, lastName]
      .filter((s) => s?.length)
      .join(' ');
    return suffix
      ? `${fullProviderTitleWithoutSuffix}, ${parsedSuffix}`
      : fullProviderTitleWithoutSuffix;
  }

  static formatTaxonomies(taxonomies: TaxonomyType[]) {
    return taxonomies
      .map((tax) => tax.subSpecialtyDescription)
      .filter(uniqueValuesFilter)
      .join(', ')
      .trim();
  }

  static formatPhoneToLocal(phoneNumber: PhoneNumberType) {
    const cleaned = `${phoneNumber.number}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const [, areaCode, prefix, lineNumber] = match;
      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }
    return phoneNumber.number;
  }

  static formatQsParamToArray(param?: any): any[] {
    if (!param) return param;
    return Array.isArray(param)
      ? param.map((tax) => String(tax).trim())
      : String(param).replace(/\s+/g, '').split(',');
  }

  static isAddressEmpty(address: Partial<FullAddress>): boolean {
    const cleanAddress = pickBy(address, identity);

    return isEmpty(cleanAddress);
  }

  static capitalizeAddress(address: string) {
    return address.split(' ').map(capitalize).join(' ');
  }

  /**
   * Format address object to a string
   * @param address
   * @param shouldCapitalizeAddress - capitalize address line 1, 2, and city
   * @param isFiveDigitZip
   * @return {string}
   */
  static formatAddress(
    address: Partial<FullAddress>,
    shouldCapitalizeAddress = true,
    isFiveDigitZip = true,
  ): string {
    let stringAddress = '';
    if (this.isAddressEmpty(address)) {
      return stringAddress;
    }
    if (address.addressLine1) {
      stringAddress = shouldCapitalizeAddress
        ? this.capitalizeAddress(address.addressLine1)
        : address.addressLine1;
    }

    if (address.addressLine2) {
      stringAddress += `${stringAddress ? ', ' : ''}${
        shouldCapitalizeAddress
          ? this.capitalizeAddress(address.addressLine2)
          : address.addressLine2
      }`;
    }
    if (address.city) {
      stringAddress += `${stringAddress ? ', ' : ''}${
        shouldCapitalizeAddress ? this.capitalizeAddress(address.city) : address.city
      }`;
    }
    if (address.state) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.state}`;
    }
    if (address.zip) {
      stringAddress += `${stringAddress ? ' ' : ''}${
        isFiveDigitZip ? address.zip.substring(0, 5) : address.zip
      }`;
    }
    return stringAddress;
  }

  /**
   * this formatter better handles fallback for missing data including grammatical elements
   * @param fullAddress
   * @returns {string}
   */
  static addressFormat(fullAddress?: Partial<FullAddress>) {
    return fullAddress ? this.formatAddress(fullAddress, false, false) : '';
  }

  static addressStringToObject(addressString = ''): FullAddress | null {
    if (isEmpty(addressString)) {
      return null;
    }

    const addressObject = parser.parseLocation(addressString);

    if (isEmpty(addressObject)) {
      return null;
    }

    const { city, state, zip = '', number: num, street, type } = addressObject;

    return {
      city,
      state,
      zip: zip || '',
      addressLine1: compact([num, street, type]).join(' '),
      addressLine2: '',
    };
  }
}

export default Formatter;
