import { BadgeTypeEnum } from '@getvim/atomic-ui';

export enum ProviderType {
  PROVIDER = 'PROVIDER',
  FACILITY = `FACILITY`,
}

export interface SpokenLanguage {
  id: string;
  name: string;
  nativeName: string;
  iso3?: string;
}

interface ClinicalMatch {
  age?: boolean;
  gender?: string;
  taxonomy: string;
}

export interface Performance {
  code: string;
  name?: string;
  description: string;
  icon?: string;
}

interface GeoLocation {
  latitude: number;
  longitude: number;
}

interface BookingHorizon {
  value: number;
  threshold: number;
}

interface PhoneNumber {
  number: string;
  countryDialingCode: string;
}

enum NetworkStatus {
  IN_NETWORK = 'IN_NETWORK',
  OUT_OF_NETWORK = 'OUT_OF_NETWORK',
  UNVERIFIED_NETWORK = 'UNVERIFIED_NETWORK',
}

interface Network {
  status: NetworkStatus;
  tier?: string;
}

export interface CostEstimation {
  description?: string;
  min?: number;
  max?: number;
}

export interface Location {
  providerLocationId?: string;
  geo?: GeoLocation;
  distance?: string;
  openHours?: string;
  bookingHorizon?: BookingHorizon;
  address?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  timezone?: string;
  officeName?: string;
  phoneNumber?: PhoneNumber;
  fax?: PhoneNumber;
  performance?: Performance[];
  internalProvider?: boolean;
  acceptNewPatients?: boolean;
  network?: Network;
  costEstimation?: CostEstimation;
  implementationSpecificFields?: any;
  // After mapping this field will substitute 'providerLocationId'
  id?: string;
}

export interface SpecialtyResponse {
  id: string;
  description: string;
  isPrimary?: boolean;
}

export interface FindResult {
  providerType: ProviderType;
  npi: string;
  name: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
  title?: string;
  gender?: string;
  profilePicture?: string;
  preferredProvider?: boolean;
  internalProvider?: boolean;
  languages: SpokenLanguage[];
  specialties: SpecialtyResponse[];
  clinicalMatch?: ClinicalMatch;
  scoring?: string;
  locations?: Location[];
  implementationSpecificFields?: any;
}

export interface PaginationResult {
  hasNextPage?: boolean;
  pageNumber?: number;
  totalPages?: number;
}

export interface FindResponse {
  results: FindResult[];
  alternativeResults: FindResult[];
  pagination: PaginationResult;
  implementationSpecificFields?: any;
}

// Another types

export enum PortalOptionType {
  Provider = 'Provider',
  Facility = 'Facility',
}

export interface CostDetail {
  message: string;
  cost: string;
}

export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface AddressWithDistanceAndPhone extends Address {
  phone: string;
  distance: string;
}

export interface BadgeDetails {
  type: BadgeTypeEnum;
  description: string;
}

export interface BaseDetails {
  acoName?: string;
  specialty: SpecialtyResponse;
  npi: string;
  tin: string;
  mpin: string;
  preferredProvider?: boolean;
  name: string;
}

export interface Taxonomy {
  taxonomyCode: string;
  taxonomyDisplayName: string;
  subSpecialtyDescription: string;
  taxonomyDescription: string;
}

export interface PortalOption {
  npi: string;
  tin?: string;
  mpin?: string;
  preferredProvider?: boolean;
  name?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
  title?: string;
  gender?: string;
  profilePicture?: string;
  internalProvider?: boolean;
  languages?: SpokenLanguage[];
  clinicalMatch?: ClinicalMatch;
  scoring?: string;
  locations: Location[];
  taxonomies: Taxonomy[];
  acceptingNewPatients?: boolean;
  tier?: string;
  tierType?: string;
}

export interface Disclaimer {
  categoryName: string;
  message: string;
  categoryRank: string;
  internalCategoryRank: string;
}

export interface GetPortalResult {
  providers: PortalOption[];
  pagination: PaginationResult;
  providerCacheId?: string;
}

export enum DDPMessage {
  'R' = 'Radiology Designated Diagnostic Benefits',
  'L' = 'Laboratory Designated Diagnostic Benefits',
  'A' = 'All Designated Diagnostic Benefits',
}

export interface Benefits {
  designatedDiagnosticProvider?: DDPMessage;
  placeOfServiceDifferential?: string;
}

export interface ComplexName {
  firstName: string;
  lastName: string;
}

// ? UHC types, maybe redundant
export interface ResponseSpecificFields {
  benefits: Benefits;
  distanceInfo: string;
  disclaimers: Disclaimer[];
  isCostAvailable: boolean;
  providerCacheId: string;
}

export interface ResultSpecificFields {
  tin: string;
  mpin: string;
  providerGroupIndicator: boolean;
  tier: string;
  tierType: string;
}

export interface ProviderResultSpecificFields extends ResultSpecificFields {
  organizationName: string;
  acceptingNewPatients: boolean;
  premiumDesignationLong: string;
  premiumDesignationText: string;
}

export interface FacilityResultSpecificFields extends ResultSpecificFields {
  organizationTypeDescription: string;
  freeStandingFacility: string;
  preferredLab: string;
  plnDdpCd: string;
}
