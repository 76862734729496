import { FindRequest } from '../../requestTypes';
import { FindQuery } from '../../../models/FindRequest';

export interface SearchOptionsInput {
  pagination?: {
    paginationNumber: number;
    providerCacheId?: string;
  };
  languageCode?: string;
  radius?: number;
  forceCost?: boolean;
}

export const getFindQuery = (input: FindRequest): FindQuery => {
  const {
    filters: { distance, taxonomy, npiList },
    searchTerm,
    geo,
    options: { memberToken },
    queryId,
    accessToken,
    limit: pageSize,
    pageNumber,
  } = input;

  return {
    searchParams: {
      geo: {
        // TODO: check, maybe rename to addressLine1
        fullAddress: geo?.address,
        // in order assist they just send zip field
        zip: geo?.zip,
        geocode: geo?.geocode,
      },
      encryptedIdentifyInput: memberToken,
    },
    filters: {
      nuccs: taxonomy ? [...taxonomy] : [],
      npiList: npiList ? [...npiList] : undefined,
      // TODO: check if we can search just by using npi or last name is mandatory
      // provider: {
      //   lastName,
      // },
      facility: {},
      distance,
    },
    pagination: {
      pageNumber: pageNumber ?? 1,
      pageSize,
    },
  };
};
