import {
  array,
  boolean,
  intersection,
  literal,
  nullType,
  number,
  partial,
  string,
  type,
  TypeOf,
  union,
} from 'io-ts';
import { SearchResultsTypeV } from '../models/SearchResults';
import { LocationTypeV, OpenHoursV } from '../models/Location';
import { EmptyArrayV } from '../models/utils';

export const TaxonomiesResponseV = type(
  {
    data: array(
      intersection([
        type({
          codes: array(string),
          name: string,
        }),
        partial({
          type: string,
          isGeneral: boolean,
          ranking: number,
        }),
      ]),
    ),
    total: number,
  },
  'TaxonomiesResponse',
);
export type TaxonomiesResponse = TypeOf<typeof TaxonomiesResponseV>;

export const FreeTextTaxonomyV = intersection(
  [
    type({
      searchTerm: string,
      ranking: number,
      filters: array(
        type({
          type: string,
          value: type({
            taxonomyCode: string,
            taxonomyDisplayName: string,
          }),
        }),
      ),
    }),
    partial({
      matchType: array(string),
    }),
  ],
  'FreeTextTaxonomy',
);
export type FreeTextTaxonomy = TypeOf<typeof FreeTextTaxonomyV>;

const FreeTextProviderTaxonomyV = intersection([
  type({
    taxonomyCode: string,
    taxonomyDisplayName: string,
    subSpecialtyDescription: string,
  }),
  partial({
    taxonomyDescription: string,
  }),
]);

const FreeTextProviderV = intersection([
  type({
    npi: string,
    ranking: number,
    firstName: string,
    lastName: string,
    taxonomies: array(FreeTextProviderTaxonomyV),
  }),
  partial({
    suffix: string,
    taxonomiesCodes: array(string),
    profilePicture: union([string, nullType]),
  }),
]);

export const FreeTextResponseV = type(
  {
    data: type({
      searchTerm: string,
      suggestions: type({
        provider: array(FreeTextProviderV),
        specialty: array(FreeTextTaxonomyV),
        icd: array(FreeTextTaxonomyV),
        cpt: array(FreeTextTaxonomyV),
        nucc: array(FreeTextTaxonomyV),
      }),
    }),
  },
  'FreeTextResponse',
);
export type FreeTextResponse = TypeOf<typeof FreeTextResponseV>;

export const FindResponseV = type(
  {
    data: SearchResultsTypeV,
  },
  'FindResponse',
);
export type FindResponse = TypeOf<typeof FindResponseV>;

const GetByNpiProviderTaxonomyV = intersection(
  [FreeTextProviderTaxonomyV, partial({ taxonomyDescription: string })],
  'GetByNpiProviderTaxonomy',
);

const GetByNpiLocationResponseV = intersection(
  [
    LocationTypeV,
    type({
      openHours: union([OpenHoursV, EmptyArrayV]),
    }),
    partial({
      sampleReview: string,
      bestDrCommunityFeedback: string,
    }),
  ],
  'GetByNpiLocationResponse',
);

export const GetByNpiResponseV = intersection(
  [
    type({
      npi: string,
      firstName: string,
      lastName: string,
      suffix: string,
      gender: union([literal('MALE'), literal('FEMALE'), literal('ANY')]),
      languages: array(
        intersection([
          type({
            id: string,
            name: string,
            nativeName: string,
          }),
          partial({
            iso3: string,
          }),
        ]),
      ),
      taxonomies: array(GetByNpiProviderTaxonomyV),
      ratingCount: number,
      locations: array(GetByNpiLocationResponseV),
    }),
    partial({
      middleName: union([string, nullType]),
      title: union([string, nullType]),
      profilePicture: union([string, nullType]),
      acceptNewPatients: union([boolean, nullType]),
      rating: union([number, nullType]),
      boardCertifications: union([literal('true'), literal('false'), nullType]),
      medicalDegree: union([string, nullType]),
      hvns: array(type({ domain: string })),
      clinicalMatch: nullType,
    }),
  ],
  'GetByNpiResponse',
);

export type GetByNpiResponse = TypeOf<typeof GetByNpiResponseV>;

export const ShareProviderByPhoneResponseV = type(
  {
    data: type({
      correlationId: string,
    }),
  },
  'ShareProviderByPhoneResponse',
);

export type ShareProviderByPhoneResponse = TypeOf<typeof ShareProviderByPhoneResponseV>;

export const ActionAnalyticsResponseV = type(
  {
    data: type({
      success: boolean,
    }),
  },
  'ActionAnalyticsResponse',
);

export type ActionAnalyticsResponse = TypeOf<typeof ActionAnalyticsResponseV>;

export const AsoMetadataResponseV = partial(
  {
    data: type({
      defaultTier: string,
      tiers: array(
        intersection([
          type({
            name: string,
            title: string,
            description: string,
            showInNetwork: boolean,
          }),
          partial({
            overrideColor: union([
              literal('gray'),
              literal('darkGray'),
              literal('red'),
              literal('orange'),
            ]),
            shouldShowStatus: boolean,
          }),
        ]),
      ),
    }),
  },
  'AsoMetadataResponse',
);

export type AsoMetadataResponse = TypeOf<typeof AsoMetadataResponseV>;
