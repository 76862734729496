import React, { PropsWithChildren } from 'react';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import config from '../config';

export default function PortalReferralGuidanceFeatureFlagProvider({
  children,
}: PropsWithChildren<any>) {
  const key = config.rollout__key;

  return <FeatureFlagProvider rolloutKey={key}>{children}</FeatureFlagProvider>;
}
