import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import useQueryString from '@getvim/components-hooks-use-query-string';
import { AnalyticsInstance } from '@getvim/components-utils-analytics';
import Formatter from '@getvim/components-utils-formatter';
import { SearchParams as SearchParamsType } from '../../api/requestTypes';
import SearchEvents from '../../utils/events/searchEvents';
import { validateParams } from './formDef';
import SearchAndAction from './SearchAndAction';
import { isTypeOf } from '../../models/utils';
import { GeoTypeV } from '../../models/Geo';
import { getAccessToken, getApiKey, setAccessToken, setApiKey } from '../../api/tokens';

const parseQuery = ({
  searchTerm,
  memberToken,
  plan,
  geo,
  insurer,
  options,
  filters = {},
}: any) => {
  if (!geo) return null;
  return {
    searchTerm,
    memberToken,
    plan,
    insurer,
    geo: {
      ...geo,
      geocode: geo.geocode
        ? {
            latitude: parseFloat(geo.geocode.latitude),
            longitude: parseFloat(geo.geocode.longitude),
          }
        : undefined,
    },
    filters: {
      ...filters,
      taxonomy: Formatter.formatQsParamToArray(filters.taxonomy),
      distance: filters.distance && parseInt(filters.distance, 10),
    },
  };
};

function generateMemberSessionId(searchParams: SearchParamsType) {
  if (searchParams?.options?.memberSessionId) return searchParams?.options?.memberSessionId;
  if (!searchParams?.memberToken) return undefined;
  return uuid();
}

function enrichWithAnalyticsTokens(searchParams: SearchParamsType, analytics: AnalyticsInstance) {
  const memberSessionId = generateMemberSessionId(searchParams);
  if (memberSessionId) {
    const { eventName, params } = SearchEvents.startMemberSession({ memberSessionId });
    analytics.track(eventName, params);
  }
  return {
    ...searchParams,
    queryId: validateParams(searchParams) ? uuid() : undefined,
    memberSessionId,
    insurer: searchParams.insurer || '',
  };
}

function getFilters(
  paramsFromSdk?: Partial<SearchParamsType>,
  paramsFromQuery?: Partial<SearchParamsType>,
) {
  const params = paramsFromSdk || paramsFromQuery;

  return {
    ...params?.filters,
  };
}

const SearchAndActionWrapper: FunctionComponent<{
  analytics: AnalyticsInstance;
  isGoogleApiLoaded: boolean;
}> = ({ analytics, isGoogleApiLoaded }) => {
  const [paramsFromQuery, setParamsFromQuery] = useState<Partial<SearchParamsType>>();
  const [paramsFromSdk, setParamsFromSdk] = useState<Partial<SearchParamsType>>();

  const searchParams = useMemo<SearchParamsType | undefined>(() => {
    let params = paramsFromSdk || paramsFromQuery;

    if (params) {
      params = {
        ...params,
        filters: getFilters(paramsFromSdk, paramsFromQuery),
      };
      if (!isTypeOf(params.geo, GeoTypeV)) {
        delete params.geo;
      }
    }

    return params as SearchParamsType | undefined;
  }, [paramsFromSdk, paramsFromQuery]);

  const clearParams = useCallback(() => {
    setParamsFromQuery(undefined);
    setParamsFromSdk(undefined);
  }, []);

  const query = useQueryString();
  const insurer = searchParams?.insurer;

  useEffect(() => {
    const queryParams = parseQuery(query);
    if (queryParams) setParamsFromQuery(enrichWithAnalyticsTokens(queryParams, analytics));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useSdkEvents((payload) => {
    setParamsFromSdk(enrichWithAnalyticsTokens(payload, analytics));
  });

  const apiKey =
    paramsFromSdk?.options?.apiKey || paramsFromSdk?.apiKey || query.apiKey || getApiKey();
  const accessToken = paramsFromSdk?.accessToken || query.accessToken || getAccessToken();
  setApiKey(apiKey as string);
  setAccessToken(accessToken as string);

  return (
    <SearchAndAction
      analytics={analytics}
      isGoogleApiLoaded={isGoogleApiLoaded}
      initialSearchParams={searchParams!}
      clearInitialSearchParams={clearParams}
    />
  );
};

export default SearchAndActionWrapper;
