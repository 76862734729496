/* eslint-disable @typescript-eslint/no-shadow */
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Text,
  ColorNameEnum,
  SizeEnum,
  WeightEnum,
  Button,
  DistanceChip,
  deprecated,
  Container,
  NoResultsX,
  ResultCard,
  Availability,
  AvailabilitySizeEnum,
  PreferredProviderBadge,
  AsoMetadata,
  NetworkStatusEnum,
  NetworkStatusChip,
} from '@getvim/atomic-ui';
import { useToasts } from 'react-toast-notifications';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import { useTheme } from '@getvim/components-hooks-use-theme';
import { AnalyticsInstance } from '@getvim/components-utils-analytics';
import { Atoms } from '../../atomic';
import { searchActionAnalytics } from '../../api/api';
import Formatter from '../../utils/formatter';
import ResultCardBanner from '../ResultCardBanner/ResultCardBanner';
import SearchAction from '../../models/SearchAction';
import SearchEvents from '../../utils/events/searchEvents';
import { prepareProviderDataToCopy, copyToClipboard } from '../../utils/copyToClipboard';

import './SearchResults.less';
import { GetPortalResult, Location, PortalOption } from '../../models/FindResponse';

export interface SearchResultsProps {
  searchResults?: GetPortalResult | null | undefined;
  onFinish: (payload?: any) => void;
  onChangePage: (p: number) => void;
  resultsLimit: number;
  pageNumber: number;
  memberToken?: string;
  queryId: string;
  memberSessionId?: string | null;
  memberPhoneNumber?: string;
  analytics?: AnalyticsInstance;
  onSelect: (npi?: string) => void;
  onConfirm: (provider: PortalOption, location: Location) => void;
  displayCopyButton: boolean;
  portal: boolean;
  asoMetadata?: AsoMetadata;
  selectedProviderLocation?: {
    provider: PortalOption;
    location: Location;
  };
  setSelectedProvider: (
    newProviderLocation:
      | {
          provider: PortalOption;
          location: Location;
        }
      | undefined,
  ) => void;
  insurer?: string;
}

function scrollResultCardToViewPort(npi?: string) {
  if (npi) {
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    const element = document.getElementById(`result-card-${npi}`);
    if (isIE) {
      element?.scrollIntoView(true);
    } else {
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}

const SearchResults: FunctionComponent<SearchResultsProps> = ({
  insurer,
  searchResults,
  onSelect,
  onConfirm,
  onFinish,
  resultsLimit,
  onChangePage,
  pageNumber,
  memberToken,
  analytics,
  queryId,
  memberSessionId,
  memberPhoneNumber,
  asoMetadata,
  selectedProviderLocation,
  setSelectedProvider,
  displayCopyButton,
  portal,
}) => {
  const { addToast, removeAllToasts } = useToasts();
  const theme = useTheme();
  const npi = selectedProviderLocation?.provider.npi;
  const [copiedData, setCopiedData] = useState<string>('');

  const { provider: selectedProvider, location: selectedLocation } = selectedProviderLocation || {
    provider: undefined,
    location: undefined,
  };

  const sdkEvents = useSdkEvents();

  useEffect(() => {
    onSelect(npi);
    setTimeout(() => scrollResultCardToViewPort(npi), 20);
  }, [onSelect, npi]);

  const portalCopiedDataToast = () => {
    if (portal && displayCopyButton) {
      addToast('Details copied', {
        appearance: 'success',
      });
    }
  };

  // Added to prevent refresh on Provider Details when toast from Search results is closed
  const removeCopyToasts = () => {
    if (displayCopyButton) removeAllToasts();
  };

  return !searchResults || !searchResults.providers.length ? (
    <div className="no-results text-center">
      {!searchResults ? (
        <div>
          <div className="svg-72 margin-bottom-30 margin-top-50">
            <Atoms.SvgSearchDoctor />
          </div>
          <Text
            weight={WeightEnum.light}
            colorName={ColorNameEnum.dark}
            className="title-36 no-margin"
          >
            Find a doctor your patient will &nbsp;
            <i className="icon-heart-vim i-va-fix-3 text-pink" />
          </Text>
        </div>
      ) : (
        <div className="no-results text-center margin-top-50">
          <Container>
            <Text
              weight={WeightEnum.bold}
              className="margin-top-30"
              size={SizeEnum['18px']}
              colorName={ColorNameEnum.theme}
            >
              Sorry,
            </Text>
            <Text
              size={SizeEnum['14px']}
              colorName={ColorNameEnum.extraDark}
              className="margin-top"
            >
              We couldn't find what you're looking for.
            </Text>
            <Text size={SizeEnum['14px']} colorName={ColorNameEnum.extraDark}>
              Please try a new search.
            </Text>
            <div className="margin-bottom-30 margin-top-50">
              <NoResultsX />
            </div>
          </Container>
        </div>
      )}
    </div>
  ) : (
    <div className="search-results" data-qa-copied-provider={copiedData}>
      {searchResults.providers.length &&
        searchResults.providers.map((provider: PortalOption) => {
          const location: Location | undefined = provider.locations.length
            ? {
                ...(selectedProvider?.npi === provider?.npi
                  ? (selectedLocation as Location)
                  : provider.locations[0]),
              }
            : undefined;
          const taxonomies = Formatter.formatTaxonomies(provider.taxonomies);
          const fullProviderName = Formatter.formatProviderTitle(provider);
          const isSelected = selectedProvider?.npi === provider.npi;
          const cardSelectedClassName = isSelected ? 'card-selected' : undefined;
          const distance =
            location && location.distance != undefined && !Number.isNaN(Number(location.distance))
              ? Math.round(Number(location.distance) * 10) / 10
              : undefined;

          return (
            <ResultCard
              sideBannerMode
              id={provider.npi}
              key={provider.npi}
              showBottomActionBanner={!portal && isSelected}
              resultImg={<deprecated.ProviderLogo provider={provider} containerClass="big" />}
              resultLeftDetail={
                <div>
                  {location && location.network && (
                    <NetworkStatusChip
                      networkStatus={NetworkStatusEnum[location.network.status]}
                      networkStatusTier={location.network.tier}
                      asoMetadata={asoMetadata}
                      hideUnverified
                    />
                  )}
                  <span data-qa-gender={provider.gender} style={{ display: 'none' }} />
                </div>
              }
              resultMainText={fullProviderName}
              resultSecondaryText={taxonomies}
              addressTitle={location && location.officeName}
              address={location && location.address}
              addressColor={
                provider.internalProvider ? ColorNameEnum.themeSecondary : ColorNameEnum.ultraDark
              }
              locationDistance={<DistanceChip distance={distance} />}
              primaryActionBtn={
                <Button
                  buttonType="small"
                  text={displayCopyButton ? 'Copy Details' : 'Select'}
                  width="small"
                  onClick={() => {
                    location && onConfirm(provider, location);
                    location && setSelectedProvider({ provider, location });
                    if (displayCopyButton) {
                      const copyData = prepareProviderDataToCopy(provider);
                      copyToClipboard(copyData);
                      setCopiedData(copyData);
                      portalCopiedDataToast();
                    }
                    searchActionAnalytics({
                      queryId,
                      actionType: SearchAction.SELECT,
                      npi: provider.npi,
                      locationId: location?.id,
                      memberSessionId,
                      ranking: 0,
                    });
                  }}
                />
              }
              verticalMode
              showShadow={false}
              horizontalActionButtons
              showLocation
              resultBottomActionBanner={
                selectedLocation &&
                selectedProvider &&
                selectedProvider.npi === provider.npi &&
                location ? (
                  <ResultCardBanner
                    insurer={insurer}
                    npi={provider.npi}
                    fullProviderName={fullProviderName}
                    location={location}
                    onCancel={() => setSelectedProvider(undefined)}
                    displayCopyButton={displayCopyButton}
                    onClose={onFinish}
                    memberToken={memberToken}
                    memberPhoneNumber={memberPhoneNumber}
                  />
                ) : undefined
              }
            >
              {provider.preferredProvider && <PreferredProviderBadge />}
              <Availability
                acceptNewPatients={provider.acceptingNewPatients || location?.acceptNewPatients}
                bookingHorizon={location?.bookingHorizon}
                size={AvailabilitySizeEnum.small}
                shortText
              />
            </ResultCard>
          );
        })}
      <deprecated.Pagination
        active={pageNumber}
        maxButtons={3}
        pagesNumber={searchResults.pagination.totalPages || 1}
        onClick={(newPageNumber) => {
          onChangePage(newPageNumber);
          const { eventName, params } = SearchEvents.changePage({
            pageNumber: newPageNumber,
            prevPage: pageNumber,
            memberSessionId,
            queryId,
          });
          analytics?.track(eventName, params);
        }}
      />
    </div>
  );
};

export default SearchResults;
