import { capitalize } from 'lodash-es';
import {
  FindResponse,
  FindResult,
  PortalOption,
  GetPortalResult,
  ResponseSpecificFields,
  ProviderType,
  Location,
} from '../../../models/FindResponse';
import { getAddress } from '../../../utils/formatter';

const getResults = (findResults: FindResult[]): PortalOption[] => {
  const results = findResults.filter(
    (suggestion) => suggestion.providerType === ProviderType.PROVIDER,
  );

  return results.length
    ? results.map((findResult) => {
        const {
          npi,
          name,
          locations,
          specialties,
          preferredProvider,
          implementationSpecificFields,
          title,
          firstName,
          middleName,
          lastName,
          suffix,
          profilePicture,
          gender,
          internalProvider,
          languages,
        } = findResult;
        const { acceptingNewPatients, tin, mpin, tier, tierType } =
          implementationSpecificFields || {};

        return {
          taxonomies: specialties
            ? specialties.map((tax) => {
                return {
                  taxonomyCode: tax.id,
                  taxonomyDisplayName: tax.description,
                  subSpecialtyDescription: tax.description,
                  taxonomyDescription: tax.description,
                };
              })
            : [],
          title,
          firstName: capitalize(firstName),
          middleName: capitalize(middleName),
          lastName: capitalize(lastName),
          suffix,
          npi,
          profilePicture,
          gender,
          internalProvider,
          acceptingNewPatients,
          tier,
          tierType,
          name,
          tin,
          mpin,
          preferredProvider,
          languages,
          locations: locations?.length
            ? locations.map((location) => {
                const { providerLocationId, ...locationItem } = location;
                const result: Location = { id: providerLocationId, ...locationItem };
                if (!location.address) {
                  result.address = getAddress(location, true);
                }

                return result;
              })
            : [],
        };
      })
    : [];
};

// eslint-disable-next-line import/prefer-default-export
export const getPortalResult = (findResponse: FindResponse): GetPortalResult => {
  const { results, alternativeResults, implementationSpecificFields, pagination } = findResponse;
  const responseSpecificFields: ResponseSpecificFields = implementationSpecificFields;
  const { providerCacheId } = responseSpecificFields || {};

  // TODO: check maybe data will be no relevant, so remove concatenation
  return {
    providers: [...getResults(results), ...getResults(alternativeResults)],
    pagination,
    providerCacheId,
  };
};
